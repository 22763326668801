import React, { useRef, useState } from 'react';
import { IoClose } from "react-icons/io5";
import emailjs from 'emailjs-com';
import logo from '../../Assets/logo/logo.svg';

const AppointmentModal = ({ onClose }) => {
    const modalRef = useRef();

    const closeModal = (e) => {
        if (modalRef.current === e.target) {
            onClose();
        }
    };

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        city: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        emailjs.send('service_xyvcc1e', 'template_voinpgp', formData, 'z7shN8aJoJopttBxt')
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                onClose();
            }, (err) => {
                console.log('FAILED...', err);
            });
    };

    return (
        <div ref={modalRef} className="fixed inset-0 z-50 bg-gray-800 bg-opacity-75 flex items-center justify-center p-4 sm:p-6" onClick={closeModal}>
            <form className="w-full sm:max-w-md md:max-w-lg lg:max-w-xl bg-[#84dbcc] px-6 sm:px-8 md:px-16 py-8 sm:py-10 md:py-12 rounded-md " onSubmit={handleSubmit}>
                <div className="flex justify-between items-center">
                    <h1 className="text-white tracking-wide text-xl sm:text-2xl md:text-3xl">Appointment Book</h1>
                    <IoClose size={25} className="cursor-pointer text-white" onClick={onClose} />
                </div>
                <div className="space-y-4 sm:space-y-6 mt-6 sm:mt-8">
                    <input type="text" className="w-full p-3 rounded-md text-black" placeholder="First Name"
                        name="name" value={formData.name} onChange={handleChange} />

                    <input type="email" className="w-full p-3 rounded-md text-black" placeholder="Email"
                        name="email" value={formData.email} onChange={handleChange} />

                    <input type="tel" className="w-full p-3 rounded-md text-black" placeholder="Phone Number"
                        name="phone" value={formData.phone} onChange={handleChange} />

                    <input type="text" className="w-full p-3 rounded-md text-black" placeholder="City"
                        name="city" value={formData.city} onChange={handleChange} />

                    <div className="flex items-center justify-between mt-4">
                        <img src={logo} alt="logo" className="w-24 sm:w-28 md:w-32" />
                        <button className="bg-white text-black px-4 py-2 rounded shadow-md hover:bg-gray-200 transition duration-200" type="submit">
                            Submit
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default AppointmentModal;
