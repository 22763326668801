import React from "react";
import img1 from "../../Assets/about/Provideimg1.png";
import img2 from "../../Assets/about/Provideimg2.png";
import img3 from "../../Assets/about/Provideimg3.png";
import img4 from '../../Assets/about/img1.jpg'
import img5 from '../../Assets/about/img2.jpg'
import img6 from '../../Assets/about/img3.jpg'
const WhatWeProvide = () => {
  return (
    <>
      <div className="flex flex-col gap-4 items-center">
        <h1 className="text-3xl md:text-5xl text-center font-serif font-semibold text-[#202020]">
          What We Provide
        </h1>
        <p className="text-center w-[70%] items-center mb-6 capitalize text-[#5F5F5F]">
          Discover a world of tranquility, luxury, and personalized care as you
          embark on a journey of self-care and renewal. Visit us today and let
          us transport you to a realm of beauty and serenity.
        </p>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-6 md:mt-3 lg:mt-0 md:mx-16 mx-6">
        <div
          className="bg-white rounded-lg shadow-md overflow-hidden"
          data-aos="flip-left"
        >
          <img
            src={img1}
            alt="Industrial"
            className="w-full h-48 object-cover"
          />
          <div className="p-4">
            <h2 className="text-xl font-bold mb-2">FACIAL CARE</h2>
            <p className="text-gray-600 text-base tracking-wide mb-4">
              Facial care includes cleansing, moisturizing, and protecting skin
              daily. Products are tailored to skin types, promoting a clear,
              radiant, and youthful-looking complexion.
            </p>
            <button className="px-4 py-2 mt-4 w-full bg-[#00B495] rounded-md md:w-32 hover:bg-[#008db4] duration-300 ease-in-out text-white">
              Book Now
            </button>
          </div>
        </div>
        <div
          className="bg-white rounded-lg shadow-md overflow-hidden"
          data-aos="flip-right"
        >
          <img src={img2} alt="Towers" className="w-full h-48 object-cover" />
          <div className="p-4">
            <h2 className="text-xl font-bold mb-2">BODY SCRUBS</h2>
            <p className="text-gray-600 text-base tracking-wide mb-4">
              Body scrubs exfoliate by removing dead skin cells, leaving skin
              smooth and refreshed. They often include hydrating oils for
              nourishment and a luxurious, sensory experience.
            </p>
            <button className="px-4 py-2 mt-4 w-full bg-[#00B495] rounded-md md:w-32 hover:bg-[#008db4] duration-300 ease-in-out text-white">
              Book Now
            </button>
          </div>
        </div>
        <div
          className="bg-white rounded-lg shadow-md overflow-hidden"
          data-aos="flip-left"
        >
          <img
            src={img3}
            alt="Fertilizer"
            className="w-full h-48 object-cover"
          />
          <div className="p-4 flex flex-col gap-2">
            <h2 className="text-xl font-bold mb-2">AROMA OIL</h2>
            <p className="text-gray-600 text-base tracking-wide mb-4">
              Aroma oils are plant-based essential oils used in aromatherapy to
              enhance mood, relaxation, and well-being, offering unique benefits
              through pleasant, natural scents.
            </p>
            <button className="px-4 py-2 mt-4 w-full bg-[#00B495] rounded-md md:w-32 hover:bg-[#008db4] duration-300 ease-in-out text-white">
              Book Now
            </button>
          </div>
        </div>
        <div
          className="bg-white rounded-lg shadow-md overflow-hidden"
          data-aos="flip-left"
        >
          <img
            src={img4}
            alt="Industrial"
            className="w-full h-48 object-cover"
          />
          <div className="p-4 flex flex-col gap-2">
            <h2 className="text-xl font-bold mb-2">HOT STONE MASSAGE</h2>
            <p className="text-gray-600 text-base tracking-wide mb-4">
              Hot stone massage uses heated stones to relieve muscle tension,
              enhance circulation, and promote relaxation, providing a soothing
              experience for both body and mind.
            </p>
            <button className="px-4 py-2 mt-4 w-full bg-[#00B495] rounded-md md:w-32 hover:bg-[#008db4] duration-300 ease-in-out text-white">
              Book Now
            </button>
          </div>
        </div>
        <div
          className="bg-white rounded-lg shadow-md overflow-hidden"
          data-aos="flip-right"
        >
          <img src={img5} alt="Towers" className="w-full h-48 object-cover" />
          <div className="p-4">
            <h2 className="text-xl font-bold mb-2">MUD WRAP</h2>
            <p className="text-gray-600 text-base tracking-wide mb-4">
              A mud wrap applies mineral-rich mud to detoxify and hydrate the
              skin, improving texture and promoting relaxation while allowing
              nutrients to penetrate deeply.
            </p>
          </div>
          <button className="px-4 py-2 mt-4 md:ml-4 w-full bg-[#00B495] rounded-md md:w-32 hover:bg-[#008db4] duration-300 ease-in-out text-white">
            Book Now
          </button>
        </div>
        <div
          className="relative bg-white rounded-lg shadow-md overflow-hidden"
          data-aos="flip-left"
        >
          <img
            src={img6}
            alt="Fertilizer"
            className="w-full h-48 object-cover"
          />
          <div className="p-4 relative">
            <h2 className="text-xl font-bold mb-2">AROMATHERAPY FACIAL</h2>
            <p className="text-gray-600 text-base tracking-wide mb-4">
              An aromatherapy facial incorporates essential oils to nourish the
              skin and enhance relaxation, leaving the complexion smooth,
              hydrated, and rejuvenated for a radiant glow.
            </p>
          </div>
          <button className="px-4 py-2 mt-4 md:ml-4 w-full bg-[#00B495] rounded-md md:w-32 hover:bg-[#008db4] duration-300 ease-in-out text-white">
            Book Now
          </button>
        </div>
      </div>
    </>
  );
};

export default WhatWeProvide;
