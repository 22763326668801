import React from 'react'

function Map() {
  return (
      
    <div className='py-2'>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d51690.80022244458!2d84.73036257981124!3d22.214816311906354!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a20175d9cddfe61%3A0xa2d849075a01bf32!2sIDC!5e0!3m2!1sen!2sin!4v1729242157672!5m2!1sen!2sin"  height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"
      className='w-full h-[250px] md:h-[400px] md:pb-8' data-aos="zoom-in"></iframe>
    </div>
  )
}
   
 
export default Map;
