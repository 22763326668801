import React from 'react'
import img from '../../Assets/about/abouts.png'
const AboutUs = () => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 p-8 md:p-16 md:gap-8 gap-2 ">
      <div className="w-full h-[100%] " data-aos="fade-right">
        <img
          className="w-full h-full md:object-cover rounded-tl-[100px] rounded-br-[75px] "
          src={img}
          alt="discoverGroupImg"
        />
      </div>
      <div className="flex flex-col md:gap-8 gap-2 md:my-auto " data-aos="fade-down">
        <div className="flex flex-col gap-2">
          <h5 className="lato-thin-italic text-[#00B495] italic ">Get to know us</h5>
          <h2 className="text-3xl md:text-5xl font-serif font-semibold text-[#202020] mb-4">
            Welcome to the Kayaspa-Bhubaneswar
          </h2>
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-black font-semibold poppins-thin leading-6 tracking-wide md:text-md text-base capitalize">
            At Kaya Spa, we believe in the art of relaxation and self-care. Our mission is to provide a serene sanctuary where you can escape the stresses of daily life and rejuvenate your mind, body, and soul. With a team of highly trained professionals, we offer a range of luxurious treatments designed to cater to your unique needs.
          </p>
        </div>
        <div>
        <button className="px-4 py-2 my-auto w-full bg-[#00B495] rounded-md md:w-32 hover:bg-[#008db4] duration-300 ease-in-out text-white">Read More</button>
        </div>
      </div>
    </div>
  )
}

export default AboutUs