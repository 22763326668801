import React from 'react'
import BookNow from '../../Components/Common/BookNow'
import OurHappyTeam from '../../Components/Common/OurHappyTeam'
import SimpleBanner from '../Common/SimpleBanner'
import AboutUs_banner from '../../Assets/about/aboutsbanar.png'
import Banner from '../../Components/Common/Banner'
import WhatWeProvideHome from '../../Components/Home/WhatWeProvideHome'
const Services = () => {
  return (
    <div>
       <SimpleBanner
        image={AboutUs_banner}
        name="Services"
        path="Home >> Services"
      />
      <WhatWeProvideHome/>
      <BookNow/>
      <OurHappyTeam />
      <Banner />
    </div>
  )
}

export default Services
