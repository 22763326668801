import React from "react";
import BannerImg from "../../Assets/Common/Banner.png";
import Phone from "../../Assets/Common/Phone.png";

function Banner() {
  return (
    <div className="flex md:px-16 md:my-8 items-center justify-center space-x-28 md:space-x-52 lg:space-x-64">
      <img
        src={BannerImg}
        alt="A banner image containing phone number and a book now button"
        className="relative h-28 md:h-auto"
      />
      <div className="flex absolute items-center gap-2 md:gap-8 lg:gap-28">
        <img src={Phone} className="w-12 md:w-20 lg:w-28" alt="Phone image" />
        <div className="flex flex-col">
          <p
            className="text-sm md:text-lg text-white md:mb-4"
            style={{ fontFamily: "Poppins, serif" }}
          >
            Need Help ? Call Us Today
          </p>
          <h1
            className="text-lg md:text-3xl lg:text-4xl  text-[#7BD8C8]"
            style={{ fontFamily: "Noto Sans Georgian, sansSerif" }}
          >
            +91-7735791829
          </h1>
          <button className="py-2 md:px-4 mt-1 md:py-2 md:mt-6 w-[50%] lg:w-32 bg-[#00B495] rounded-md md:rounded-md md:w-[60%] hover:bg-[#008db4] duration-300 ease-in-out text-white text-xs md:text-base">Book Now</button>

         
        </div>
      </div>
    </div>
  );
}

export default Banner;
