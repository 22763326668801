import React from 'react'
import BookNow from '../../Components/Common/BookNow'
import Map from '../../Components/Contact/Map'
import AboutUs_banner from '../../Assets/about/aboutsbanar.png'
import SimpleBanner from '../Common/SimpleBanner'
import Banner from '../../Components/Common/Banner'

const Contact = () => {
  return (
    <div>
      <SimpleBanner
        image={AboutUs_banner}
        name="Services"
        path="Home >> Services"
      />
      <BookNow />
      <Map/>
      <Banner />
    </div>
  )
}

export default Contact
