import React from "react";
import logo from "../../Assets/Common/logo.png";
import rightLogo from "../../Assets/Common/rightLogo.png";
import rightLogo2 from "../../Assets/Common/rightLogo2.png";
import checkLogo from "../../Assets/Common/checkLogo.png";
import { useState } from "react";
import { useEffect } from "react";
import axios from 'axios'
const SpaTreatmentsWithPricing = () => {
  const [plans,setPlans] = useState([])
    const getALlPlans = ()=>{
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_API_KEY}/plans/getAllPlansByBranchId/674586da9aa6780fb2688681`,
            headers: { }
          };
          axios.request(config)
          .then((response) => {
            console.log(response.data);
        
            setPlans(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
    }
    useEffect(()=>{
        getALlPlans();
    },[])

  return (
    <div className="bg-white items-center py-4 md:pt-24 mx-2 gap-4">
      <div className="flex flex-col gap-2 text-center">
        <h5 className="lato-thin-italic text-[#00B495] italic ">About Pricing</h5>
        <h2 className="text-3xl md:text-5xl font-serif font-semibold text-[#202020] mb-4">
          Spa Treatments With Pricing
        </h2>
      </div>
      <div className="grid grid-cols-1 px-6 md:px-16 gap-4 md:grid-cols-2 lg:grid-cols-3 items-center justify-center space-y-6 mt-10 md:space-y-0 md:space-x-0">
        {plans.map((plan) => (
          <div
            key={plan.id}
            className="p-10 rounded-lg shadow-lg border text-center border-[#00B495] group relative"
            data-aos="fade-down"
          >
            <img
              src={rightLogo}
              alt="design"
              className="absolute top-0 left-0 opacity-100 group-hover:opacity-0 transition-opacity duration-500"
            />
            <img
              src={rightLogo2}
              alt="design"
              className="absolute top-0 left-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
            />
            <div className="flex justify-center mb-4">
              <img src={logo} alt="Service logo" />
            </div>
            <h2 className="text-2xl font-bold mb-2 font-serif text-[#00B495] text-center">
              {plan.type}
            </h2>
            <div className="text-3xl font-bold text-[#434242] mb-2 text-center">
              <span>₹</span>{plan.price} <span className="text-lg font-normal text-center">/Per Day</span>
            </div>
            <hr className="border-[#00B495] my-4" />
            <ul className="md:ml-20 lg:ml-auto xl:ml-20 space-y-5 mb-6 text-start">
              {plan.keyPoints.map((feature, index) => (
                <li key={index} className="flex items-center mb-2">
                  <img src={checkLogo} alt="check icon" className="mr-2" />
                  {feature}
                </li>
              ))}
            </ul>
            <button className="px-4 py-2 mt-auto w-full bg-[#00B495] rounded-md md:w-32 hover:bg-[#008db4] duration-300 ease-in-out text-white">
            Book Now
            </button>
          </div>
        ))}
      </div>

    </div>
  );
};

export default SpaTreatmentsWithPricing;
