import React from 'react'
import AboutUs from '../../Components/Common/AboutUs'
import WhatWeProvide from '../../Components/abouts/WhatWeProvide'
import SimpleBanner from '../../Screens/Common/SimpleBanner'
import AboutUs_banner from '../../Assets/about/aboutsbanar.png'
import OurHappyTeam from '../../Components/Common/OurHappyTeam'
import WhatTheySay from '../../Components/Common/WhatTheySay'
import Banner from '../../Components/Common/Banner'
const About = () => {
  return (
    <div>
      <SimpleBanner
        image={AboutUs_banner}
        name="About"
        path="Home >> About"
      />
      <AboutUs />
      <WhatWeProvide />
      <OurHappyTeam/>
      <WhatTheySay />
      <Banner />
    </div>
  )
}

export default About
