import React, { useEffect, useState } from "react";
import OurOffers1 from "../../Assets/Offers/Image1.png";
import OurOffers2 from "../../Assets/Offers/Image2.png";
import OurOffers3 from "../../Assets/Offers/Image3.png";
import OurOffers4 from "../../Assets/Offers/Image4.png";
import Aos from "aos";
import "aos/dist/aos.css";
import axios from 'axios'
Aos.init({
  duration: 1200,
  offset: 200,
  once: true,
});
const OurOffers = () => {

  const [offers, setOffers] = useState([]);
  const getAllOffers = () => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: 'http://localhost:5000/pgc-api/offer/getAllOffersByBranchId/674586da9aa6780fb2688681',
      headers: {}
    };

    axios.request(config)
      .then((response) => {
        console.log(response.data);
        setOffers(response.data)
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(() => {
    getAllOffers();
  }, [])

  return (
    <div className=" md:mx-16 mx-6 mt-10">
      <h1 className="text-[#202020] text-3xl md:text-4xl lg:text-5xl my-4  md:my-6 font-serif font-bold tracking-wide text-center overflow-hidden">Our Offers</h1>
      <p className="text-center text-gray-600 mb-8">
        Discover a world of tranquility, luxury, and personalized care as you
        embark on a journey of self-care and renewal. Visit us today and let us
        transport you to a realm of beauty and serenity.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {offers.map((offer) => (
          <div
            key={offer.id}
            className="p-4 rounded-lg shadow-lg"
            data-aos={offer.animation}
          >
            <img
              src={offer.image}
              alt={offer.imageAlt}
              className="w-full rounded-lg mb-4"
            />
            <h2 className="text-xl font-bold mb-2 text-[#1D1D1D]">{offer.name}</h2>
            <p>{offer.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurOffers;
