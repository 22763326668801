import React from 'react'
import BookNow from '../../Components/Common/BookNow'
import OurOffers from '../../Components/Offers/OurOffer'
import AboutUs_banner from '../../Assets/about/aboutsbanar.png'
import SimpleBanner from '../Common/SimpleBanner'
import Banner from '../../Components/Common/Banner'
const Offers = () => {
  return (
    <div>
      <SimpleBanner
        image={AboutUs_banner}
        name="Offers"
        path="Home >> About"
      />
      <OurOffers/>
      <BookNow />
      <Banner />
    </div>
  )
}

export default Offers