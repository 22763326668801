import React, { useEffect } from "react";
import image1 from "../../Assets/Common/OurHappyTeam/image1.svg";
import image2 from "../../Assets/Common/OurHappyTeam/image2.svg";
import image3 from "../../Assets/Common/OurHappyTeam/image3.svg";
import image4 from "../../Assets/Common/OurHappyTeam/image4.svg";
import { useState } from "react";
import axios from "axios";

const data = [
  {
    id: 1,
    name: "Sofia Khan",
    role: "Spa Manager",
    profileImage: image1,
  },
  {
    id: 2,
    name: "Rajesh Kumar",
    role: "Beauty Therapist",
    profileImage: image2,
  },
  {
    id: 3,
    name: "Neha Gupta",
    role: "Nail Technician",
    profileImage: image3,
  },
  {
    id: 4,
    name: "Vikram Singh",
    role: "Esthetician",
    profileImage: image4,
  },
];

const OurHappyTeam = () => {
  const [team, setTeam] = useState([]);
  const showTeam = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/team/getTeamByBranchId/674586da9aa6780fb2688681`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Response: ", response.data);
        setTeam(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    showTeam();
  }, []);

  return (
    <div className="flex px-8 py-2 md:py-4 md:px-16 gap-4 md:gap-16 flex-col items-center">
      <div className="flex justify-center items-center flex-col gap-2">
        <h5 className="lato-thin-italic italic text-[#00B495]">Our Team</h5>
        <h2 className="text-3xl md:text-5xl text-center font-serif font-semibold text-[#202020]">
          Our Happy Team
        </h2>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mx-6 md:mx-16 gap-8 ">
        {team.map((item) => (
          <Card key={item?.id} teamMember={item} />
        ))}
      </div>
    </div>
  );
};

export default OurHappyTeam;

function Card({ teamMember }) {
  return (
    <div
      key={teamMember?.id}
      className="flex flex-col items-center h-fit text-center  relative  "
    >
      <div className="flex w-full h-full object-cover">
        <img src={teamMember?.profilePic} alt={teamMember?.name} className="w-full h-full object-cover"/>
      </div>
      <div className="my-2 lg:my-4 hover:bg-[#050F64] md:px-12 hover:text-white transition-all duration-300 ease-in-out rounded-lg p-4 text-[#050F64]">
        <h3 className="font-bold font-serif text-md md:text-[24px]">
          {teamMember?.name}
        </h3>
        <h4 className="text-[#00B495] font-semibold  text-[10px] md:text-sm">
          {teamMember?.role}
        </h4>
      </div>
    </div>
  );
}
