import React from "react";
import image from "../../Assets/Home/homeimg.png";
import smallImg from "../../Assets/Home/homeimgsmallsceen.png";
import { useState, useEffect } from "react";
import AppointmentModal from "../Layout/AppointmentModal";
import axios from "axios";

const HeroSection = () => {
  const [open, setOpen] = useState(false);
  const [heroSectionData, setHeroSectionData] = useState();

  const showHeroSection = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/heroSection/getHeroSection/674586da9aa6780fb2688681`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Response : ", response.data);
        setHeroSectionData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    showHeroSection();
  }, []);

  const handleAddOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };
  return (
    <React.Fragment>
      <div
        className="hidden px-6 md:px-10 md:block md:py-8 lg:py-20 h-auto text-center"
        style={{
          backgroundImage: `url(${heroSectionData?.[0]?.bgImg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "left",
        }}
      >
        <div className="text-start text-white p-8 max-w-7xl z-10 md:w-full flex flex-col gap-6">
          <h1 className="text-3xl md:text-4xl lg:text-6xl font-serif font-semibold mb-2 tracking-wider md:leading-tight md:w-full lg:w-[70%]">
            {/* Elevate Physically And Mentally With Our Spa Massages */}
            {heroSectionData?.[0]?.heading}
          </h1>
          <p className="text-base md:text-lg mb-4 tracking-wide text-[#3F3F3F] md:w-[70%] xl:w-[50%]">
            {/* Conveniently Unleash Interoperable Ideas With Multimedia Based
            Convergence Massage */}

            {heroSectionData?.[0]?.subheading}
          </p>

          <button
            className="px-4 py-2 mt-auto w-full bg-[#00B495] rounded-md md:w-32 hover:bg-[#008db4] duration-300 ease-in-out text-white"
            onClick={handleAddOpen}
          >
            Book Now
          </button>
        </div>
        {open && <AppointmentModal onClose={handleModalClose} />}
      </div>

      <div
        className="md:hidden block py-10 h-auto text-center"
        style={{
          backgroundImage: `url(${heroSectionData?.[0]?.bgImg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right",
        }}
      >
        <div className="text-start text-white p-8 max-w-7xl z-10 md:w-1/2 md:space-y-12">
          <h1 className="text-3xl md:text-6xl font-medium mb-4 tracking-wider font-serif ">
            {heroSectionData?.[0]?.heading}
          </h1>
          <p className="text-base md:text-lg mb-6 tracking-wide mt-6 text-[#3F3F3F]">
            {heroSectionData?.[0]?.subheading}
          </p>

          <button
            className="px-4 py-2 mt-auto w-full bg-[#00B495] rounded-md md:w-[40%] hover:bg-[#008db4] duration-300 ease-in-out text-white"
            onClick={handleAddOpen}
          >
            Book Now
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HeroSection;
