import React from 'react'
// import image from '../../Assets/footerbg.png'
import { FaFacebookF, FaInstagram, FaPinterestSquare, FaYoutubeSquare } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import logo from '../../Assets/logo/logo.svg'


const Footer = () => {
    const currentYear = new Date().getFullYear();
    const navigate = useNavigate();
    return (
        <React.Fragment>
            <footer className="footer-bg bg-cover bg-center" style={{
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
            }}>
                <div className="px-4 py-10  bg-[#F4FFFD]">
                    <div className="grid md:grid-cols-[1.5fr_2fr_1fr] lg:grid-cols-[1fr_2fr_1fr] grid-cols-1 gap-10 w-[90%] mx-auto">
                        <div className="md:ml-11">
                            <h2 className="text-lg font-bold mb-4 text-[#0C1669]">QUICK LINKS</h2>
                            <ul className='text-[#00B495]'>
                                <li onClick={() => { navigate('/'); window.scrollTo(600, 0); }} className="mb-2 flex items-center cursor-pointer">Home</li>
                                <li onClick={() => { navigate('/about'); window.scrollTo(600, 0); }} className="mb-2 flex items-center cursor-pointer">About Us</li>
                                <li onClick={() => { navigate('/services'); window.scrollTo(600, 0); }} className="mb-2 flex items-center cursor-pointer">Services</li>
                                <li onClick={() => { navigate('/offers'); window.scrollTo(600, 0); }} className="mb-2 flex items-center cursor-pointer">Offers</li>
                                <li onClick={() => { navigate('/contact'); window.scrollTo(600, 0); }} className="mb-2 flex items-center cursor-pointer">Contact</li>
                            </ul>
                        </div>
                        <div className="text-center lg:mr-20 xl:mr-32 text-[#00B495] flex flex-col items-start md:items-center">
                            <img src={logo} alt="logo" className='w-36 md:w-fit lg:w-36'/>
                            <p className="mb-4 text-start md:text-center mt-4">Passionate Experts Ready to Serve You: Uniting Skill, Dedication, and Innovation for Your Ultimate Satisfaction.</p>
                            <div className="flex justify-center space-x-4 text-[#0C1669]">
                                <div>
                                    <a href='https://www.facebook.com/p/Kaira-Salon-61555288714974/' target="_blank" rel="noopener noreferrer" >
                                        <FaFacebookF/></a>

                                </div>
                                <div>
                                    <a href="https://www.instagram.com/kaira.salon/" target="_blank" rel="noopener noreferrer">
                                        <FaInstagram/>
                                    </a>
                                </div>
                                <FaTwitter />
                                
                                <FaYoutubeSquare />
                                <FaPinterestSquare />
                            </div>
                        </div>
                        <div>
                            <h2 className="text-lg font-bold mb-4 text-[#0C1669]">CONTACT US</h2>
                            <p className="mb-2 text-[#00B495]"><i className='fa fa-map-marker fa-lg mr-4 text-[#0C1669]'></i>Marwari Para, Jharsuguda, Odisha,768201</p>
                            <p className="mb-2 text-[#00B495]"><i className="fa fa-envelope fa-lg mr-4 text-[#0C1669]"></i>kayaspabhubaneswar@gmail.com</p> 
                            <p className="mb-2 text-[#00B495]"><i className="fa fa-phone fa-lg text-[#0C1669] mr-4"></i>+91-7735791829</p>
                        </div>
                    </div>
                </div>
            </footer>
            <div className="py-4 bg-[#050F64] text-[#F1F1F1]">
                <div className="md:mx-16 px-4 flex flex-wrap justify-between items-center">
                    <p className="w-full sm:w-auto text-center sm:text-left">© {currentYear} KAYA SPA BHUBANESWAR  All Rights Reserved.</p>
                    <p className="w-full sm:w-auto text-center sm:text-right">
                        Design & Developed by{' '}
                        <a
                            href="https://www.webbocket.com/"
                            target="_blank"
                            rel="noopener noreferrer"                        >
                            WEB_BOCKET
                        </a>
                    </p>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Footer;